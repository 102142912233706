<template>
    <div>
        <van-cell-group :title="key" v-for="(group, key) in groupedMenus" :key="key">
            <van-cell :title="item.NAME" v-for="item in group" :key="item.ID" is-link size="large" :icon="item.ICON"
                @click="showShare = true" :to="item.URL" />
        </van-cell-group>
        <van-share-sheet v-if="id=='52'" v-model="showShare" title="请选择类型" :options="options" @select="onSelect" />
    </div>
</template>


<script>
    import { Toast } from "vant";
    export default {
        name: "index",
        data() {
            return {
                menus: [],
                id: this.$route.query.id,
                groupedMenus: [],
                showShare: false,
                options: [],
                typeList: [],
                itemList: []
            }
        },
        created() {
            if (this.id == 0 || this.id == undefined || this.id == null) {
                Toast.fail("id empty")
                this.$router.push('/');
            }
            var user = this.whale.user.get()
            this.menus = user.REL_M_MENU.filter(x => x.PARENT_ID == this.id)

            switch (this.id) {
                case '22':
                    this.getSafeMenus();
                    break;
                case '52':
                    this.getInsMenus();
                    break;
                default:
                    this.getGroupMenus();
            }
        },
        methods: {
            getSafeMenus() {
                let self = this
                this.whale.remote.getCollection({
                    url: '/api/Mobile/SAFE/SafeClassConfig/GetPageList',
                    completed(m) {
                        m.ITEMS.forEach((x, i) => {
                            self.menus.push({
                                GROUP: '巡检',
                                ID: x.ID,
                                NAME: x.Safe_Class_Name,
                                ICON: 'newspaper-o',
                                SORT: i + 1,
                                URL: '/safe/type' + `${x.Safe_Class_Name == '安全巡检' ? '' : '2'}` + '?name=' + x.Safe_Class_Name + '&Safe_Class_Id=' + x.ID + '&date=' + x.Frequency_Txt
                            })
                        });
                        self.getGroupMenus()
                    }
                })
            },
            getInsMenus() {
                this.getGroupMenus();
                let self = this;
                this.whale.remote.getResult({
                    url: "/api/Mobile/INS/RecordApi/GetTypes",
                    completed: function (res) {
                        self.typeList = res.DATA
                        self.options = res.DATA.map(x => ({ id: x.ID, name: x.NAME, icon: x.ICON_KV.Value }))
                    },
                });
            },
            onSelect(option) {
                this.itemList = this.typeList.find(x => x.NAME == option.name).ITEM_LIST
                if (this.itemList.length > 0) {
                    this.$router.push({
                        path: "/ins/new",
                        query: { tid: option.id, tname: option.name, items: JSON.stringify(this.itemList) },
                    });
                } else {
                    Toast.fail(`${option.name} 没有项目`);
                }
                this.showShare = false;
            },
            getGroupMenus() {
                this.groupedMenus = this.menus.sort((a, b) => a.SORT - b.SORT).reduce((groups, item) => {
                    const groupName = item.GROUP;
                    if (!groups[groupName]) {
                        groups[groupName] = [];
                    }
                    groups[groupName].push(item);
                    return groups;
                }, {});
            }
        },
        computed: {

        }
    }

</script>